<template>
  <div>
    <div class="header">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="df">
            <span  class="inpTitle">资金方：</span>
              <el-select
                ref="formUnitNo"
                v-model="form.unitNo"
                clearable
                placeholder="请选择资金方"
                @change="selectChange"
              >
                <el-option
                  v-for="opt in agentList"
                  :key="opt.unitNo"
                  :value="opt.unitNo"
                  :label="opt.name"
                ></el-option>
              </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="df">
            <span class="inpTitle">还款日期：</span>
            <el-input v-model="payDay" disabled></el-input>
          </div>
        </el-col>

      </el-row>
      <div class="df mt20 mb20">
        <div class="borderBox">
          <div class="closeAccountCard">
            <p>本期应还金额</p>
            <div class="df moneyBox">
              <h6 class="money">￥{{moneyList.needPayAmount || 0}}</h6>
            </div>
            <div>
              <p class="time">*截止至前一日23:59:59</p>
            </div>
          </div>
        </div>
        <div class="borderBox">
          <div class="closeAccountCard">
            <p>本期单笔分账已还金额</p>
            <div class="df moneyBox">
              <h6 class="money">￥{{moneyList.alreadyPayAmount || 0}}</h6>
            </div>
            <div>
              <p class="time">*截止至前一日23:59:59</p>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <el-form ref="form" :model="searchForm" label-width="120px" :inline="true" class="mt20">
        <el-form-item label="还款记录编号：">
          <el-input v-model="searchForm.number" clearable></el-input>
        </el-form-item>
        <el-form-item label="发起时间：">
          <el-date-picker
           :default-time="['00:00:00']"
            v-model="timeArr"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始时间"
            value-format="timestamp"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="资金方：">
          <el-select
            ref="searchFormUnitNo"
                v-model="searchForm.unitNo"
                clearable
                placeholder="请选择资金方"
              >
              <el-option
                v-for="opt in agentList"
                :key="opt.unitNo"
                :value="opt.unitNo"
                :label="opt.name"
              ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="还款状态">
          <el-select ref="searchFormPayStatus" v-model="searchForm.payStatus" placeholder="请选择还款状态" clearable  >
            <el-option
              v-for="item in REPAY_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="还款结果">
          <el-select ref="searchFormPayResult" v-model="searchForm.payResult" placeholder="请选择还款结果" clearable>
            <el-option
              v-for="item in REPAY_RESULT"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="queryRepaymentRecord" class="ml24">查询</el-button>
      </el-form>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column  fixed="left" prop="number" label="还款记录编号" width="180"/>
        <el-table-column prop="createTime" label="发起时间"  width="160"/>
        <el-table-column prop="unitName" label="资金方" width="80"/>
        <el-table-column
          prop="payStatus"
          label="还款状态"
          width="80">
          <template slot-scope="scope">
            {{$changeObj(REPAY_STATUS)[scope.row.payStatus]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="payResult"
          label="还款结果"
          width="80">
          <template slot-scope="scope">
            {{$changeObj(REPAY_RESULT)[scope.row.payResult]}}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="currentPeriodMustPayCapital"
          label="本期应还金额">
            <template slot-scope="scope">
            ￥{{scope.row.currentPeriodMustPayCapital}}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="separateAlreadyPayAccount"
          label="分账已还金额">
            <template slot-scope="scope">
            ￥{{scope.row.separateAlreadyPayAccount}}
          </template>
        </el-table-column>
        <el-table-column
          prop="secondarySeparateAccount"
          label="二次分账金额">
            <template slot-scope="scope">
            ￥{{scope.row.secondarySeparateAccount}}
          </template>
        </el-table-column>
        <el-table-column
          prop="needOfflinePayAccount"
          label="需线下还款金额">
            <template slot-scope="scope">
            ￥{{scope.row.needOfflinePayAccount}}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="currentPeriodSurplusPayAccount"
          label="本期多还金额">
            <template slot-scope="scope">
            ￥{{scope.row.currentPeriodSurplusPayAccount}}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          prop="relationOrderQuantity"
          label="关联订单数量">
        </el-table-column>
        <el-table-column
          prop="separateFailOrderQuantity"
          label="分账失败订单数量">
        </el-table-column>
        <el-table-column
          width="180"
          prop="beginTime,endTime"
          label="订单时间范围">
          <template slot-scope="scope">
            <p>{{scope.row.beginTime}}</p>
            <p>{{scope.row.endTime}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          >
          <template slot-scope="{row}">
            <el-button type="primary" @click="repaymentExport(row)"  size="small">
              关联订单导出
            </el-button>
        </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination mt20 ">
      <el-pagination
        class="mb20"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {REPAY_STATUS,REPAY_RESULT} from '@/js/constant';
import { queryHaiLiAgent } from "@/api/common";
import {queryRepaymentAmount,queryRepaymentRecord,repaymentExport} from '@/api/closeAccount'
export default {
  data() {
    return {
      REPAY_STATUS,
      REPAY_RESULT,
      timeArr:[],
      searchForm:{//查询参数
          pageSize:20,
          beginIndex:0,
          unitNo:'',
          payStatus:'',
          payResult:'',
          number:'',
          endTime:'',
          beginTime:''
      },
      agentList:[],
      total:1,
      options:[],
      // settleDate:'',//还款日期
      tableData: [],
      form:{
        unitNo:'',
      },
      pageSize:20,
      beginIndex:1,
      moneyList:{}
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.formUnitNo.blur()
        _this.$refs.searchFormUnitNo.blur()
        _this.$refs.searchFormPayStatus.blur()
        _this.$refs.searchFormPayResult.blur()
        _this.queryRepaymentRecord()
      }
    };
    this.queryRepaymentAmount();
    this.queryHaiLiAgent();
    this.queryRepaymentRecord();
  },
  computed:{
     payDay(){
       if(this.moneyList.settleDate){
          return `每月${this.moneyList.settleDate}日`;
       }else{
         return ''
       }
     }
  },
  methods: {
    // 分页
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryRepaymentRecord();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    // 下拉资金方改变
    selectChange(val){
       this.form.unitNo = val;
       this.queryRepaymentAmount();
    },
     // 获取还款金额
    async queryRepaymentAmount(){
      try{
        const params = {
          unitNo:this.form.unitNo
        }
        const res = await queryRepaymentAmount(params);
        this.moneyList = res.mdata;
      }catch(e){
        console.log(e,'e');
      }
    },
    // 查询
     onSearch() {
      this.beginIndex = 1;
      this.queryRepaymentRecord();
    },
     async queryRepaymentRecord(){
       let page = this.beginIndex;
      page = page - 1;
       try{
         if(this.timeArr && this.timeArr.length !== 0){
            this.searchForm.beginTime = this.timeArr[0];
            this.searchForm.endTime = this.timeArr[1];
         }else{
           this.searchForm.beginTime = null;
            this.searchForm.endTime = null;
         }
        const params = {
          ...this.searchForm,
          beginIndex: page,
          pageSize: this.pageSize
        };
        const res = await queryRepaymentRecord(params);
        this.tableData = res.ldata;
        this.total = res.mdata.total;
      }catch(e){
        console.log(e,'e');
      }
    },
     // 资金方下拉数据
    async queryHaiLiAgent(){
      try{
      const params = {
        role: 0
      };
      const res = await queryHaiLiAgent(params);
      this.agentList = res.ldata;
      }catch(e){
        console.log(e,'e');
      }
    },

    // 结算订单导出
    async repaymentExport(row){
      console.log(row,'row');
        try{
          const params = {
            number:row.number
          };
          const res = await repaymentExport(params);
          if(res.result === 1){
            this.$router.push('/leaseholdMgmt/exportTask');
          }
      }catch(e){
        console.log(e);
      }
    },
  },
  components: {

  }
}
</script>

<style scoped lang="less">
  @import './repayment.less';
</style>
