import axios from "../js/ajax";
/** 结算金额查询 */
export const getQuerySettleAmount = params =>
  axios.get("/managementPlatform/repaymentSettle/querySettleAmount", { params });
// /** 创建结算记录 */
export const getCreateSettleOrder = params =>
  axios.get("/managementPlatform/repaymentSettle/createSettleOrder", { params });
// /** 结算管理查询 */
export const querySettleRecord = params =>
  axios.get("/managementPlatform/repaymentSettle/querySettleRecord", { params });
// 资金方还款金额
export const queryRepaymentAmount = params =>
  axios.get("/managementPlatform/repaymentSettle/queryRepaymentAmount", { params });
// 还款记录查询
export const queryRepaymentRecord = params =>
  axios.get("/managementPlatform/repaymentSettle/queryRepaymentRecord", { params });
//结算导出按钮
export const settleExport = params =>
axios.get("/electricity/lease/data/settle/export", { params });
//还款导出按钮
export const repaymentExport = params =>
axios.get("/electricity/lease/data/repayment/export", { params });
